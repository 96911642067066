.aboutuscontent {
  /*background-color: rgb(136, 189, 188,0.70);
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
}
.aboutus {
  padding-top: calc(20vh - 12px);
}

.card-deck .card {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
  background-color: white;
}

@media screen and (max-width: 600px) {
  .tagline{
     font-size: 10px;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
