.tuition-data {
  padding-top: calc(20vh - 12px);
}
.tuition-data {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
}

.card {
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
}

@media screen and (max-width: 600px) {
  .montserratfontxl {
    font-size: 1.75rem;
  }
  .tuition-data {
    margin-top: 15%;
  }
}

.btn-color {
  background-color: rgb(163, 53, 69);
}

.div .button {
  background-color: firebrick;
}

.div .div .div .button .btn .btn-primary {
  background-color: firebrick;
}

.enroll-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: white;
}

.enroll-btn .tooltiptext {
  visibility: hidden;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  background-color: rgba(208, 223, 240);
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  margin: auto;
  right: 35px;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  
}

.enroll-btn:hover .tooltiptext {
  visibility: visible;
}
