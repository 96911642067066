.home-data {
  padding-top: calc(16vh - 12px);
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
}

.home-page-image-mobile {
  display: none;
}

.side-img {
  border-radius: 10px;
}

.home-page-text {
  letter-spacing: 2px;
}

.home-page-image {
  padding: 5px;
}

.home-page-row {
  padding: 5px 40px;
}

main {
  height: calc(100% - (150px));
  padding-bottom: calc(20vh - 12px);
}

#root > div:nth-child(2) > div > main > div > div.col-lg-6.w-100.home-page-image{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (min-width: 50px) and (max-width: 500px) {
  .home-page-text > h2 {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .home-page-text > ul {
    font-size: 14px;
  }

  .home-data {
    padding-top: calc(16vh - 12px);
  }

  .home-page-row {
    margin-top: calc(12vh - 12px);
    padding: 5px 40px;
  }

}

@media screen and (min-width: 501px) and (max-width: 769px) {
  .home-data {
    padding-top: calc(16vh - 12px);
  }

  .home-page-row {
    margin-top: calc(20vh - 12px);
    padding: 5px 40px;
  }
}

@media screen and (min-width: 501px) and (max-width: 770px) {
  .image-slider {
    padding-bottom: calc(5vh - 12px);
    margin: 10px;
  }

  .home-page-text > h2 {
    font-size: 1.5rem;
  }

  .home-data {
    padding-top: calc(20vh - 12px);
  }

  .home-page-row {
    margin-top: calc(5vh - 12px);
  }

}

.info-head {
  padding: 10px 5px 5px 20px;
  margin: 10px;
  text-align: center;
  font-weight: bold;
}

.info-box {
  padding: 10px 5px 5px 20px;
  border-radius: 0 10px 0 10px;
  margin-top: 10px;
}
