@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital@1&display=swap");


.header {
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
}

.navlinks{
  margin: auto;
}

.header-fontl {
  font-family: "Work Sans", sans-serif;
  font-size: 1.25rem;
 
}

.sub-header {
  font-family: "Work Sans", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.75px;
  font-weight: lighter;
  color: rgb(0, 123, 255);
  font-weight: bold;
}

.header-fontxl {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: bolder;
}

.header-fontxl:hover{
  text-decoration: none;
  color: rgb(0, 123, 255);
}

.menu-margin {
  margin-right: 0px;
  margin-left: 40px;
}


@media only screen and (max-width: 800px) {
  .menu-margin {
    margin-right: 0px;
    margin-left: 0px;
  }
}
