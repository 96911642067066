@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital@1&display=swap");

.font-normal {
  font-family: "Work Sans", sans-serif;
  font-size: calc(0.75rem + ((1vw - 7.68px) * 0.3472)); 
}

.font-l{
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.125rem + ((1vw - 7.68px) * 0.1736));
}

.font-xl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.25rem + ((1vw - 7px) * 0));
}

.font-xxl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.5rem + ((1vw - 7px) * 0));
}

.font-xxxl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(2.5rem + ((1vw - 7px) * 0));
}

.font-s{
  font-family: "Work Sans", sans-serif;
  font-size: 1.1rem;
}

.font-xs {
  font-family: "Work Sans", sans-serif;
  font-size: calc(0.5rem + 1vw)
}

.font-xxs {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
}


@media screen and (max-width: 600px) {
  .myfontxl {
    font-size: 3rem;
    text-align: center;
  }
}

.App-header {
  background-repeat: no-repeat;
  font-size: calc(6px + 2vmin);
  padding-top: 4%;
}

.App-link {
  color: #61dafb;
}

.imp-test {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.myeffect {
  -ms-transform: scale(1, 1); /* IE 9 */
  transform: scale(1, 1); /* Standard syntax */
  transition-timing-function: ease-out;
}
.myeffect:hover {
  -ms-transform: scale(1.05, 1.05); /* IE 9 */
  transform: scale(1.05, 1.05); /* Standard syntax */
  text-decoration: none;
}

.partition{
  border-top: 1px solid rgba(68, 146, 248, 0.75);
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer {
  border-top: 1px solid rgba(68, 146, 248, 0.75);
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  text-align: center;
  background-color: white;
  width: calc(100vw - 20px);
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}



footer > div > div > div.col-md-4.text-md-left > div > a{
  text-decoration: none;
  color: rgb(0, 123, 255);
}

@media screen and (max-width:980px) {
   .footer-cdglogo{
     font-size: calc(1.6rem + 1vh);
      text-align: center;
   }

   .footer-data{
    padding-bottom: calc(10vh - 12px);
   }

   .copyright-txt{
      margin: 0;
      padding: 0;
      font-size: 12px;
   }

  

}

@media screen and (min-width:981px) {
  .footer-cdglogo{
    font-size: calc(1.5rem + 0.75vh);
    text-align: center;
 }

 .copyright-txt{
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.sub-header{
  font-size: 12px;
  font-size: calc(0.50rem + 0.25vh);
}

.footer-contact-info{
  font-size: calc(0.75rem + 0.25vh);
}

} 






