.contactus {
  padding-top: calc(20vh - 12px);
  
}
.contactusdata {
  /*background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
}

main{
  height: calc(100% - (150px));
  padding-bottom: calc(20vh - 12px);
}


.card{
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
}

@media screen and (min-width: 750px) and (max-width:980px) {
  .font-normal{
    font-size: 12px;
  }
  .font-l{
    font-size: 14px;
  }
}








