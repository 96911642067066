.curriculum {
  padding-top: calc(20vh - 12px);
}
.curriculum-data {
  /*background: rgb(34,193,195,0.60);
  background: linear-gradient(0deg, rgba(34,193,195,0.50) 4%, rgba(45,157,253,0.50) 96%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.card-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.card {
  background-color: white;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.side-info {
  background-color: rgb(95, 92, 86, 0.8);
  color: white;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.details-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
}

summary {
  outline: none;
}

.cfont{
  text-align: center;
}

.footer-align{
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  .div .section {
    margin-top: 10%;
  }

  .col-sm .btn-primary {
    margin-top: 7%;
  }

  .cfont{
    font-size: 1.75rem;
  }
}

.col-sm .btn-primary {
  background-color: rgb(23, 162, 184);
}

.col-sm .btn-primary:hover {
  background-color: rgb(23, 162, 184, 0.8);
}
table tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
	background-color:rgb(23, 162, 184,0.60);
}
