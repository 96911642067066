@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital@1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
}

.navlinks{
  margin: auto;
}

.header-fontl {
  font-family: "Work Sans", sans-serif;
  font-size: 1.25rem;
 
}

.sub-header {
  font-family: "Work Sans", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.75px;
  font-weight: lighter;
  color: rgb(0, 123, 255);
  font-weight: bold;
}

.header-fontxl {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: bolder;
}

.header-fontxl:hover{
  text-decoration: none;
  color: rgb(0, 123, 255);
}

.menu-margin {
  margin-right: 0px;
  margin-left: 40px;
}


@media only screen and (max-width: 800px) {
  .menu-margin {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.font-normal {
  font-family: "Work Sans", sans-serif;
  font-size: calc(0.75rem + ((1vw - 7.68px) * 0.3472)); 
}

.font-l{
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.125rem + ((1vw - 7.68px) * 0.1736));
}

.font-xl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.25rem + ((1vw - 7px) * 0));
}

.font-xxl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(1.5rem + ((1vw - 7px) * 0));
}

.font-xxxl {
  font-family: "Work Sans", sans-serif;
  font-size: calc(2.5rem + ((1vw - 7px) * 0));
}

.font-s{
  font-family: "Work Sans", sans-serif;
  font-size: 1.1rem;
}

.font-xs {
  font-family: "Work Sans", sans-serif;
  font-size: calc(0.5rem + 1vw)
}

.font-xxs {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
}


@media screen and (max-width: 600px) {
  .myfontxl {
    font-size: 3rem;
    text-align: center;
  }
}

.App-header {
  background-repeat: no-repeat;
  font-size: calc(6px + 2vmin);
  padding-top: 4%;
}

.App-link {
  color: #61dafb;
}

.imp-test {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.myeffect { /* IE 9 */
  transform: scale(1, 1); /* Standard syntax */
  transition-timing-function: ease-out;
}
.myeffect:hover { /* IE 9 */
  transform: scale(1.05, 1.05); /* Standard syntax */
  text-decoration: none;
}

.partition{
  border-top: 1px solid rgba(68, 146, 248, 0.75);
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer {
  border-top: 1px solid rgba(68, 146, 248, 0.75);
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  text-align: center;
  background-color: white;
  width: calc(100vw - 20px);
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}



footer > div > div > div.col-md-4.text-md-left > div > a{
  text-decoration: none;
  color: rgb(0, 123, 255);
}

@media screen and (max-width:980px) {
   .footer-cdglogo{
     font-size: calc(1.6rem + 1vh);
      text-align: center;
   }

   .footer-data{
    padding-bottom: calc(10vh - 12px);
   }

   .copyright-txt{
      margin: 0;
      padding: 0;
      font-size: 12px;
   }

  

}

@media screen and (min-width:981px) {
  .footer-cdglogo{
    font-size: calc(1.5rem + 0.75vh);
    text-align: center;
 }

 .copyright-txt{
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.sub-header{
  font-size: 12px;
  font-size: calc(0.50rem + 0.25vh);
}

.footer-contact-info{
  font-size: calc(0.75rem + 0.25vh);
}

} 







.contactus {
  padding-top: calc(20vh - 12px);
  
}
.contactusdata {
  /*background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
}

main{
  height: calc(100% - (150px));
  padding-bottom: calc(20vh - 12px);
}


.card{
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
}

@media screen and (min-width: 750px) and (max-width:980px) {
  .font-normal{
    font-size: 12px;
  }
  .font-l{
    font-size: 14px;
  }
}









.curriculum {
  padding-top: calc(20vh - 12px);
}
.curriculum-data {
  /*background: rgb(34,193,195,0.60);
  background: linear-gradient(0deg, rgba(34,193,195,0.50) 4%, rgba(45,157,253,0.50) 96%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.card-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.card {
  background-color: white;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.side-info {
  background-color: rgb(95, 92, 86, 0.8);
  color: white;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.details-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: white;
}

summary {
  outline: none;
}

.cfont{
  text-align: center;
}

.footer-align{
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  .div .section {
    margin-top: 10%;
  }

  .col-sm .btn-primary {
    margin-top: 7%;
  }

  .cfont{
    font-size: 1.75rem;
  }
}

.col-sm .btn-primary {
  background-color: rgb(23, 162, 184);
}

.col-sm .btn-primary:hover {
  background-color: rgb(23, 162, 184, 0.8);
}
table tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
	background-color:rgb(23, 162, 184,0.60);
}

.tuition-data {
  padding-top: calc(20vh - 12px);
}
.tuition-data {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
}

.card {
  background-color: white;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
}

@media screen and (max-width: 600px) {
  .montserratfontxl {
    font-size: 1.75rem;
  }
  .tuition-data {
    margin-top: 15%;
  }
}

.btn-color {
  background-color: rgb(163, 53, 69);
}

.div .button {
  background-color: firebrick;
}

.div .div .div .button .btn .btn-primary {
  background-color: firebrick;
}

.enroll-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: white;
}

.enroll-btn .tooltiptext {
  visibility: hidden;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  background-color: rgba(208, 223, 240);
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  margin: auto;
  right: 35px;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  
}

.enroll-btn:hover .tooltiptext {
  visibility: visible;
}

.aboutuscontent {
  /*background-color: rgb(136, 189, 188,0.70);
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);*/
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  width: 100%;
}
.aboutus {
  padding-top: calc(20vh - 12px);
}

.card-deck .card {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5%;
  background-color: white;
}

@media screen and (max-width: 600px) {
  .tagline{
     font-size: 10px;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-data {
  padding-top: calc(16vh - 12px);
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
}

.home-page-image-mobile {
  display: none;
}

.side-img {
  border-radius: 10px;
}

.home-page-text {
  letter-spacing: 2px;
}

.home-page-image {
  padding: 5px;
}

.home-page-row {
  padding: 5px 40px;
}

main {
  height: calc(100% - (150px));
  padding-bottom: calc(20vh - 12px);
}

#root > div:nth-child(2) > div > main > div > div.col-lg-6.w-100.home-page-image{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (min-width: 50px) and (max-width: 500px) {
  .home-page-text > h2 {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .home-page-text > ul {
    font-size: 14px;
  }

  .home-data {
    padding-top: calc(16vh - 12px);
  }

  .home-page-row {
    margin-top: calc(12vh - 12px);
    padding: 5px 40px;
  }

}

@media screen and (min-width: 501px) and (max-width: 769px) {
  .home-data {
    padding-top: calc(16vh - 12px);
  }

  .home-page-row {
    margin-top: calc(20vh - 12px);
    padding: 5px 40px;
  }
}

@media screen and (min-width: 501px) and (max-width: 770px) {
  .image-slider {
    padding-bottom: calc(5vh - 12px);
    margin: 10px;
  }

  .home-page-text > h2 {
    font-size: 1.5rem;
  }

  .home-data {
    padding-top: calc(20vh - 12px);
  }

  .home-page-row {
    margin-top: calc(5vh - 12px);
  }

}

.info-head {
  padding: 10px 5px 5px 20px;
  margin: 10px;
  text-align: center;
  font-weight: bold;
}

.info-box {
  padding: 10px 5px 5px 20px;
  border-radius: 0 10px 0 10px;
  margin-top: 10px;
}

